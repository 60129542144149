import { FunctionComponent } from 'react';
import { oneLine } from 'common-tags';

import { default as TwoColumnExport } from '.';

import { pickChild } from '@/lib/utils';

import { TypeTwoColumnProps } from './types';

/**
 * Permite intercambiar las columnas pero este comportamiento solo
 * ocurre en desktop, en mobile la primera columna siempre irá arriba.
 */
export const TwoColumn: FunctionComponent<TypeTwoColumnProps> = ({
  className = '',
  gap = '',
  reverse = false,
  children,
  ...props
}: TypeTwoColumnProps) => {
  const [, Right] = pickChild(children, TwoColumnExport.Right);
  const [, Left] = pickChild(children, TwoColumnExport.Left);

  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-[1fr_1fr] ${gap} ${className}`}
      {...props}
    >
      {Left && (
        <Left.type
          {...Left.props}
          className={oneLine`
            ${reverse ? 'order-2' : 'order-1'}
            ${Left.props.className ?? ''}
          `}
        />
      )}

      {Right && (
        <Right.type
          {...Right.props}
          className={oneLine`
            ${reverse ? 'order-1' : 'order-2'}
            ${Right.props.className ?? ''}
          `}
        />
      )}
    </div>
  );
};
