import { Key, useEffect, useState } from 'react';

import {
  FlyOut,
  Menu,
} from '@/molecules';
import { Footer, Header, ModalSuscripcion } from '@/organisms';
import { PreFooter } from './preFooter';
import Logo from '@/stories/assets/logo.jpg';
import LogoFooter from '@/stories/assets/logonavidul.png';
import { TypItemMenu, TypeCard } from '@/molecules/fly-out/types';
import Snackbar from '@/atoms/snackbar';
import { GlobalElements } from '@/utils/drupal/get-global-elements';
import { PreviewAlert } from './previewAlert';
import { ParseDrupalIcon } from '@/utils/parseDrupalIcon';

export interface LayoutProps extends GlobalElements {
  meta?: any,
  canonical?: string;
}
const BaseLayout = ({
  children,
  theme = 'light',
  preFooter,
  blocks,
}: any) => {
  const [hasScroll, setHasScroll] = useState(false);
  const [suscripcion, setSuscripcion] = useState('');
  const dateCopy = new Date().getFullYear();

  useEffect(() => {
    const storeScroll = () => {
      setHasScroll(window.scrollY > 0);
    };

    document.addEventListener('scroll', storeScroll, {
      passive: true,
    });

    return () => document.removeEventListener('scroll', storeScroll);
  }, [setHasScroll]);

  const iconClasses = function (index) {
    return index == 0 ? 'flex flex-row items-center gap-2' : 'hidden md:flex md:flex-row md:items-center md:gap-2'
  }
  return (
    <div className="contenedor" >
      {suscripcion !== '' && (
        <ModalSuscripcion evento={setSuscripcion} email={suscripcion} />
      )}
      < div className="relative" >
        <PreviewAlert />
        <Snackbar href="" label="" variant="beige">
          {

            blocks.headerSettings.field_icon_items?.length ? blocks.headerSettings.field_icon_items.map((icon, index) => (
              <div key={index} className={iconClasses(index)}>
                {ParseDrupalIcon({ icon: icon.field_icon })}
                <span>{icon.field_plain_text}</span>
              </div>)) : null
          }
        </Snackbar>

        <div
          className={`fixed z-10 left-0 right-0 ${hasScroll ? ' top-0' : ' top-8'
            }`}
        >
          <Header
            className="[&:not([data-scroll='false'])]:bg-white [&:not([data-scroll='false'])]:text-text-primary [&:not([data-scroll='false'])]:shadow"
            data-scroll={hasScroll}
            logo={{ src: Logo.src }}
            socialMedias={

              blocks.socialBlock.field_social_links.map((icon) => ({
                image: icon.field_icon.field_media_svg.resourceIdObjMeta.markup,
                href: icon.field_link.uri,
              }))
            }
            theme={theme}
          >
            <Header.Menu>
              <Menu>
                {blocks.headerSettings.field_menus?.map(
                  (
                    item: {
                      field_title: string;
                      field_links: TypItemMenu[];
                      field_see_more_link: string | undefined;
                      field_highlighted_links: TypeCard[],
                      field_inner_title: string;
                    },
                    index: Key | null | undefined
                  ) => (

                    < Menu.Item key={index} title={item.field_title} >
                      <FlyOut
                        menu={item.field_links}
                        viewMore={item.field_see_more_link}
                        cards={item.field_highlighted_links}
                        titleLeft={item.field_inner_title ? item.field_inner_title : item.field_title}
                      />
                    </Menu.Item>

                  )
                )}
              </Menu>
            </Header.Menu>
            <Header.ButtonMobile link="/es/productos-ibericos/jamon">
              comprar jamón
            </Header.ButtonMobile>
            <Header.Sidebar link="/es/contacto" className="text-lg md:text-xs md:leading-[18px]">Contacto</Header.Sidebar>

            {/* <Header.Sidebar link={userID === '' ? '/es/login' : '/es/cuenta'}>
              Mi Cuenta
            </Header.Sidebar> */}
            <Header.Sidebar link="https://tienda.navidul.es/account/login" className="text-lg md:text-xs md:leading-[18px]">
              Mi Cuenta
            </Header.Sidebar>
          </Header>
        </div>

        {children}
      </div >

      {preFooter ? <PreFooter block={blocks.preFooterSettings} event={setSuscripcion} />
        : null
      }

      < Footer
        image={{
          src: LogoFooter.src,
          alt: 'Logo Navidul',
          width: LogoFooter.width,
          height: LogoFooter.height,
        }}
      >
        <Footer.Menu
          menus={blocks.footerSettings.field_top_links}

        />
        <Footer.SocialMedia
          title="SÍGUENOS:"
          socialMedias={
            blocks.socialBlock.field_social_links.map((icon: { field_icon: { field_media_svg: { resourceIdObjMeta: { markup: any; }; }; }; field_link: { uri: any; }; }) => ({
              image: icon.field_icon.field_media_svg.resourceIdObjMeta.markup,
              href: icon.field_link.uri,
            }))
          }
        />

        <Footer.Legal
          legales={blocks.footerSettings.field_bottom_links}
        />

        <Footer.Copyright>
          © {dateCopy} {blocks.footerSettings.field_copyright}
        </Footer.Copyright>
      </Footer >
    </div >
  );
};

export default BaseLayout;
