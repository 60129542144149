import { FunctionComponent } from 'react';
import Image from 'next/image';

import * as childs from './childs';
import { Container } from '@/atoms';

import { pickChild } from '@/lib/utils';

import { TypeFooterProps } from './types';
import { DrupalBlock } from 'next-drupal';

export interface FooterProps {
    menus: Record<string, never>,
    blocks: {
        socialBlock: DrupalBlock
        footerSettings:DrupalBlock
        preFooterSettings:DrupalBlock
    }
}


export const Footer: FunctionComponent<TypeFooterProps> = ({
  className = '',
  image,
  children,
}: TypeFooterProps) => {
  const [, menu] = pickChild(children, childs.Menu);
  const [, legal] = pickChild(children, childs.Legal);
  const [, socialMedia] = pickChild(children, childs.SocialMediaDisplay);
  const [, copyright] = pickChild(children, childs.Copyright);

  return (
    <Container
      as="section"
      wrapper
      backgroundColor="bg-bg-primary"
      classNameWrapper={`gap-10 ${className}`}
    >
      <div className="flex flex-col justify-between items-center gap-11 lg:flex-row">
        <Image
          src={image.src}
          alt={image.alt ?? 'Footer Logo'}
          width={image.width}
          height={image.height}
          draggable="false"
          className="flex-shrink-0"
        />
        {menu}
      </div>

      <hr className="text-primary-50 opacity-10" />

      <div className="flex flex-col justify-between items-center gap-11 lg:gap-4 xl:gap-11 lg:flex-row">
        {socialMedia}
        <hr className="text-primary-50 opacity-10 w-full lg:hidden" />
        {legal}
        <hr className="text-primary-50 opacity-10 w-full lg:hidden" />
        {copyright}
      </div>
    </Container>
  );
};

Footer.displayName = 'Footer';
