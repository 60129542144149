import Link from 'next/link';
import { Form, Formik } from 'formik';

import { Button, Container } from '@/atoms';
import { ButtonVariant } from '@/atoms/button/types';
import {
  Accordion,
  Prose,
  TextinputButton,
  TwoColumn,
} from '@/molecules';
import Pig from '@/stories/assets/pre-footer/pig.png';
import Plant from '@/stories/assets/pre-footer/plant.png';
import { Faqs} from '@/organisms';


export function PreFooter({block, event}) {
    const setSuscripcion = event;
    return (
<Container wrapper padding="pt-4 md:pt-8" backgroundColor="bg-white">
            <TwoColumn>
              <TwoColumn.Left>
                <Container padding="p-4 xl:pl-0 md:py-16 md:pr-16">
                  <Faqs
                    title={block.field_faq_title}
                    description={block.field_faq_text}
                  >
                    {block.field_faqs?.map((item: any, index: number) => (
                      < Accordion
                        key={index}
                        title={item.field_title}
                        variant="normal"
                        className="border-b border-secondary-200"
                      >
                        <Prose
                          className="text-secondary-700"
                          html={item.field_text}
                        />
                      </Accordion>
                    ))}
                  </Faqs>
                </Container>
              </TwoColumn.Left>
              <TwoColumn.Right>
                <Container
                  backgroundColor="bg-primary-200"
                  padding="px-6 py-8 md:pr-10 md:pl-16 md:py-16 xl:px-16"
                  classNameWrapper="gap-5 text-text-tertiary"
                  backgroundImage={Plant.src}
                  wrapper
                >
                  <p className="text-subtitle text-subtitle--l">{block.field_help_title}</p>

                  <p className="text-body text-body--l">
                    {block.field_help_text}
                  </p>

                  <div className="w-max">
                    <Link
                      href={block.field_help_cta.url}
                      prefetch={false}
                      passHref
                      legacyBehavior
                    >
                      <Button as="a" variant={ButtonVariant.PRIMARY}>
                        {block.field_help_cta.title}
                      </Button>
                    </Link>
                  </div>
                </Container>

                <Container
                  backgroundColor="bg-primary-600"
                  padding="px-6 py-8 md:pr-10 md:pl-16 md:py-16 xl:px-16"
                  classNameWrapper="gap-5 text-text-alternative"
                  backgroundImage={Pig.src}
                  wrapper
                >
                  <p className="text-subtitle text-subtitle--l">
                    {block.field_newsletter_title}
                  </p>

                  <p className="text-body text-body--l">
                    {block.field_newsletter_text}
                  </p>

                  <Formik
                    initialValues={{
                      email: '',
                    }}
                    onSubmit={(data) => {
                      setSuscripcion(data.email);
                    }}
                  >
                    <Form>
                      <TextinputButton
                        className="text-text-primary"
                        name="email"
                        placeholder="Escribe tu correo electrónico"
                      />
                    </Form>
                  </Formik>
                </Container>
              </TwoColumn.Right>
            </TwoColumn>
          </Container>
)};