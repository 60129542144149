import { FunctionComponent } from 'react';
import Link from 'next/link';

import { Button } from '@/atoms';

import { TypeMenu, TypeSiderbar } from './types';
import { ButtonVariant } from '@/atoms/button/types';

export const MenuAtom: FunctionComponent<TypeMenu> = ({
  children,
}: TypeMenu) => <div className="flex">{children}</div>;

export const Sidebar: FunctionComponent<TypeSiderbar> = ({
  link,
  children,
  className,
}: TypeSiderbar) => <Link href={link} className={className}>{children}</Link>;

export const ButtonMobile: FunctionComponent<TypeSiderbar> = ({
  link,
  children,
}: TypeSiderbar) => (
  <Link href={link} passHref prefetch={false} legacyBehavior>
    <Button
      data-button
      as="a"
      className="lg:hidden !w-full md:mb-6 mb-4"
      variant={ButtonVariant.PRIMARY}
    >
      {children}
    </Button>
  </Link>
);
