import { ButtonMobile, MenuAtom, Sidebar } from './childs';
import { Header } from './Header';

export type TypeDesktopHeader = typeof Header & {
  Menu: typeof MenuAtom;
  Sidebar: typeof Sidebar;
  ButtonMobile: typeof ButtonMobile;
};

(Header as TypeDesktopHeader).Menu = MenuAtom;
(Header as TypeDesktopHeader).Sidebar = Sidebar;
(Header as TypeDesktopHeader).ButtonMobile = ButtonMobile;

MenuAtom.displayName = 'DesktopHeader.Menu';
Sidebar.displayName = 'DesktopHeader.Sidebar';
ButtonMobile.displayName = 'DesktopHeader.ButtonMobile';

export type { TypeHeaderProps, TypeMenu, TypeSiderbar } from './types';
export default Header as TypeDesktopHeader;
