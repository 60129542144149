import { FunctionComponent } from 'react';

import { Link } from '@/atoms';

import { TypeFaqsProps } from './types';
import { LinkVariant } from '@/atoms/link/types';

export const Faqs: FunctionComponent<TypeFaqsProps> = ({
  className = '',
  title,
  description,
  children,
  label,
  href,
}: TypeFaqsProps) => (
  <div className={className}>
    {title && description && (
      <div className={`flex flex-col gap-6 md:items-start ${className}`}>
        {title && (
          <span className="text-subtitle text-subtitle--l">{title}</span>
        )}

        {description && (
          <span className="text-body text-body--l text-text-primary">
            {description}
          </span>
        )}
      </div>
    )}
    <hr className="text-secondary-200 mt-6" />

    <div className="flex flex-col text-left md:justify-between">{children}</div>

    {href && label && (
      <div className="mt-6 text-center md:text-start">
        <Link href={href} variant={LinkVariant.POSITIVE} icon="right">
          {label}
        </Link>
      </div>
    )}
  </div>
);

Faqs.displayName = 'Faqs';
