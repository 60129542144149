import { Footer } from './Footer';
import * as childs from './childs';

export type TypeFooter = typeof Footer & {
  Menu: typeof childs.Menu;
  SocialMedia: typeof childs.SocialMediaDisplay;
  Legal: typeof childs.Legal;
  Copyright: typeof childs.Copyright;
};

(Footer as TypeFooter).Menu = childs.Menu;
(Footer as TypeFooter).SocialMedia = childs.SocialMediaDisplay;
(Footer as TypeFooter).Legal = childs.Legal;
(Footer as TypeFooter).Copyright = childs.Copyright;

childs.Menu.displayName = 'Footer.Menu';
childs.SocialMediaDisplay.displayName = 'Footer.SocialMedia';
childs.Legal.displayName = 'Footer.Legal';
childs.Copyright.displayName = 'Footer.Copyright';

export type { TypeFooterProps } from './types';
export type {
  CopyRightProps,
  LegalProps,
  MenuProps,
  SocialMediaProps,
  TypeMenu,
} from './childs/types';
export default Footer as TypeFooter;
