import { Element, HTMLReactParserOptions, attributesToProps, domToReact } from "html-react-parser";
import parse from "html-react-parser";

export function ParseDrupalIcon({ icon, width = undefined, height = undefined, className = undefined }) {
    if (!icon || icon == undefined) return null;
    const options: HTMLReactParserOptions = {
        replace: (domNode) => {
            if (domNode instanceof Element) {
                if (domNode.name === "svg") {
                    const props = attributesToProps(domNode.attribs);
                    props.width = width || props.width
                    props.height = height || props.height
                    props.className = className || props.className
                    return <svg {...props}>{domToReact(domNode.children, options)}</svg>
                }
            }
        },
    }
    return <>{icon.field_media_svg?.resourceIdObjMeta?.markup ?
        parse(icon.field_media_svg.resourceIdObjMeta.markup, options) : parse(icon, options)}</>;
}

