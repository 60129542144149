import { IconType, defaultProps } from './types';

/**
 * Icono User
 */
export const User = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.887 10.604a3.585 3.585 0 1 0 0-7.17 3.585 3.585 0 0 0 0 7.17Zm0 1.434a5.019 5.019 0 1 0 0-10.038 5.019 5.019 0 0 0 0 10.038ZM9.736 14.189a4.302 4.302 0 0 0-4.302 4.302v1.792a.717.717 0 1 1-1.434 0v-1.792a5.736 5.736 0 0 1 5.736-5.736h4.302a5.736 5.736 0 0 1 5.736 5.736v1.792a.717.717 0 1 1-1.434 0v-1.792a4.302 4.302 0 0 0-4.302-4.302H9.736Z"
      fill={color}
    />
  </svg>
);
