import { TwoColumn } from './TwoColumn';
import { Column } from './child';

export type TypeTwoColumn = typeof TwoColumn & {
  Right: typeof Column;
  Left: typeof Column;
};

(TwoColumn as TypeTwoColumn).Right = Column.bind({});
(TwoColumn as TypeTwoColumn).Left = Column.bind({});

(TwoColumn as TypeTwoColumn).Right.displayName = 'TwoColumn.Right';
(TwoColumn as TypeTwoColumn).Left.displayName = 'TwoColumn.Left';

export type { TypeTwoColumnProps, TypeColumn } from './types';
export default TwoColumn as TypeTwoColumn;
