import { FunctionComponent } from 'react';

import { SocialMedia } from '@/molecules';

import { SocialMediaProps } from './types';

export const SocialMediaDisplay: FunctionComponent<SocialMediaProps> = ({
  className = '',
  title,
  socialMedias,
}: SocialMediaProps) => (
  <SocialMedia
    title={title}
    socialMedias={socialMedias}
    className={className}
  />
);

SocialMediaDisplay.displayName = 'SocialMediaDisplay';
