import { FunctionComponent } from 'react';
import Link from 'next/link';
import { oneLine } from 'common-tags';

import { MenuProps, TypeMenu } from './types';

export const Menu: FunctionComponent<MenuProps> = ({
  className = '',
  menus,
}: MenuProps) => (
  <div
    className={oneLine`
      flex flex-col uppercase gap-11 text-center tracking-widest text-text-alternative text-link text-link--m lg:flex-row lg:gap-14 
      ${className}
    `}
  >
    {menus?.map((menu: TypeMenu, index: number) => (
      <Link key={index} href={menu.url} prefetch={false}>
        {menu.title}
      </Link>
    ))}
  </div>
);

Menu.displayName = 'Menu';
