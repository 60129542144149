import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';

import { Container } from '@/atoms';

import { SuscriptionForm } from '@/molecules';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export interface modalNewsletterProps {
  evento: Dispatch<SetStateAction<string>>,
  email: string,
}

const ModalSuscripcion = ({ evento, email }: modalNewsletterProps) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    !open && evento('');
  }, [open]);

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4 text-center md:w-full bg-neutral-900 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-[300px] md:w-[500px] transform bg-white text-left align-middle shadow-xl">
                <Container wrapper padding="pt-8" backgroundColor="bg-white">
                  <div className="grid grid-rows-[100px_minmax(170px,_1fr)_50px] p-10">
                    <p className="text-headline--h3 text-center">
                      Suscríbete a nuestra newsletter
                    </p>
                    <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
                      <SuscriptionForm
                        backgroundColor="#ffffff"
                        title="Datos de suscripción"
                        emailLabel="Email"
                        defaultEmail={email}
                        lastNameLabel="Apellidos"
                        messageLabel="Mensaje"
                        nameLabel="Nombre"
                        textButton="Aceptar"
                        evento={setOpen}
                      />
                    </GoogleReCaptchaProvider>
                  </div>
                </Container>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalSuscripcion;
