import { FunctionComponent } from 'react';

import Link from '../link';

import { LinkVariant } from '../link/types';
import { TypeSnackbarProps } from './types';

/**
 * Snackbar
 */
export const Snackbar: FunctionComponent<TypeSnackbarProps> = ({
  className,
  variant,
  children,
  label,
  href,
}: TypeSnackbarProps) => (
  <>
    <div
      className={`\
      flex flex-row items-center justify-center h-10 gap-14 \
      ${variant === 'beige'
          ? 'bg-primary-50 text-text-tertiary text-body text-body--m'
          : 'bg-tertiary-500 text-primary-50 text-body text-body--m'
        }\
      ${className}\
    `}
    >
      {children}
      {label !== '' && href !== '' ? (
        <>
          <Link
            variant={LinkVariant.NEGATIVE}
            icon="right"
            href={href}
            className="hidden md:flex md:flex-row md:items-center"
          >
            {label}
          </Link>
          <Link
            variant={LinkVariant.NEGATIVE}
            icon="right"
            href={href}
            className="block md:hidden"
          >
            {' '}
          </Link>
        </>
      ) : null}
    </div>
  </>
);

Snackbar.displayName = 'Snackbar';
