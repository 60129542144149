import { FunctionComponent } from 'react';

import { CopyRightProps } from './types';

export const Copyright: FunctionComponent<CopyRightProps> = ({
  className = '',
  children,
}: CopyRightProps) => (
  <div
    className={`text-text-alternative text-center text-body text-body--s ${className}`}
  >
    {children}
  </div>
);

Copyright.displayName = 'Copyright';
