import { FunctionComponent } from 'react';
import { Button, Textinput } from '@/atoms';
import { ArrowRight } from '@/icons';
import { ButtonVariant } from '@/atoms/button/types';
import { TypeTextinputButtonProps } from './types';

export const TextinputButton: FunctionComponent<TypeTextinputButtonProps> = ({
  className = '',
  name,
  placeholder,
  children,
  label,
  disabled = false,
  type = 'submit',
  onClick,
}: TypeTextinputButtonProps) => (
  <div className={`flex flex-row ${className}`} aria-disabled={disabled}>
    <Textinput
      label={label}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      className="flex flex-col h-14 w-full"
      border={false}
    >
      {children}
    </Textinput>

    <Button
      variant={ButtonVariant.TEXTINPUT}
      type={type}
      disabled={disabled}
      {...(onClick && { onClick })}
    >
      <ArrowRight width={24} height={24} />
    </Button>
  </div>
);

TextinputButton.displayName = 'TextinputButton';
