import { FunctionComponent } from 'react';
import Link from 'next/link';
import { oneLine } from 'common-tags';

import { LegalProps, TypeMenu } from './types';
import { isRelative } from '@/utils/utils';

export const Legal: FunctionComponent<LegalProps> = ({
  className = '',
  legales,
}: LegalProps) => (
  <div
    className={oneLine`
      grid grid-cols-2 uppercase gap-7 text-center justify-center tracking-[0.2rem] text-text-alternative text-caption text-caption--s lg:flex lg:flex-row lg:flex-wrap lg:gap-3 xl:gap-4
      ${className}
    `}
  >
    {legales?.map((legal: TypeMenu, index: number) => (
      <Link key={index} href={legal.url} prefetch={false} target={isRelative(legal.url) ? "_self" : "_blank"} className={legales.length % 2 !== 0 && index == legales.length - 1 ? (`col-[1_/_span_2] mx-auto w-1/2 lg:w-100`) : null}>
        {legal.title}
      </Link>
    ))}
  </div>
);

Legal.displayName = 'Legal';
