import { ErrorMessage, Field, FieldProps } from 'formik';
import { FunctionComponent } from 'react';
import { oneLine } from 'common-tags';
import { TypeTextinputProps } from './types';

export const Textinput: FunctionComponent<TypeTextinputProps> = ({
  className = '',
  name,
  label,
  type = 'text',
  disabled = false,
  placeholder = '',
  maximo = 5000,
  labelOpacity = true,
  border = true,
}: TypeTextinputProps) => (
  <Field name={name}>
    {({ field, meta: { error, touched } }: FieldProps) => (
      <div className={`flex flex-col ${className}`}>
        <div
          className={`flex flex-col gap-2 ${labelOpacity && disabled ? "opacity-40" : ""}`}
          aria-disabled={disabled}
        >
          {label && (
            <label
              htmlFor={name}
              className={oneLine`
                text-body text-body--m
                ${error && touched ? 'text-tertiary-500' : 'text-text-secondary'}
              `}
            >
              {label}
            </label>
          )}

          <input
            id={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maximo}
            className={oneLine`
              text-body text-body--l
              ${!labelOpacity && disabled ? "opacity-40" : null}
              ${error && touched
                ? 'ring-tertiary-500 border-tertiary-500 focus:border-tertiary-500 active:border-tertiary-500 placeholder:text-tertiary-500 focus:ring-tertiary-500 active:ring-tertiary-500'
                : `
                      placeholder:text-text-secondary focus:ring-primary-500 active:ring-primary-500
                      ${border
                  ? 'border-secondary-600 focus:border-primary-500 active:border-primary-500'
                  : 'border-none'
                }
                    `
              }
              ${className}
            `}
            {...field}
          />
        </div>

        <ErrorMessage name={name}>
          {(msg) => (
            <div className="pt-2 text-body text-body--s text-tertiary-500">
              {msg}
            </div>
          )}
        </ErrorMessage>
      </div>
    )}
  </Field >
);

Textinput.displayName = 'Textinput';
