import { FunctionComponent } from 'react';

import { TypeMenuIconProps } from './types';

/**
 * MenuIcon
 */
export const MenuIcon: FunctionComponent<TypeMenuIconProps> = ({
  className = '',
  open,
  ...props
}: TypeMenuIconProps) => (
  <div className={`inline-flex gap-2 flex-col ${className} ${open ? 'mb-[9px]' : ''}`} {...props} >
    <hr className={`w-8 h-0.5 ${open ? 'translate-y-2.5 -rotate-45' : ''}`} />
    <hr className={`w-8 h-0.5 ${open ? 'rotate-45' : ''}`} />
  </div>
);

MenuIcon.displayName = 'MenuIcon';
